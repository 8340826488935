import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css';
import { Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import Icon from '../Icon';
import Image from '../Image/Image';
import ImageMagnifier from './ImageMagnifier';
import constants from 'src/utils/constants';
import { transform_image_url } from 'src/utils/ImageConstants';

export interface ImageProps {
	id?: string;
	src?: string | undefined;
	fallback?: string;
}
export interface CarouselProps {
	images?: ImageProps[];
	width?: string | number;
	style?: any;
	imageHeight?: string | number;
	imageWidth?: string | number;
	magnifierBoxDimension?: number;
}

const customRenderThumb = (children: any) =>
	children.map((item: any) => {
		const imageId = item.props.id;
		const imageSrc = item.props.src;
		const fallbackImageId = item.props.fallback;
		return (
			<Image
				key={imageId}
				src={transform_image_url(imageSrc, 'PDP_THUMBNAIL')}
				fallbackSrc={fallbackImageId}
				alt={fallbackImageId}
				imgClass='border_radius thumbs_border object_fit'
				width='60px'
				height='60px'
			/>
		);
	});

const ImageCarousel = ({ images, width, magnifierBoxDimension, imageHeight, imageWidth, style }: CarouselProps) => (
	<Carousel
		showIndicators={false}
		renderArrowPrev={(onClickHandler, hasPrev) =>
			hasPrev && (
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					onClick={onClickHandler}
					className='arrow'
					style={{ left: 15, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
					<Icon iconName='IconChevronLeft' />
				</Grid>
			)
		}
		renderArrowNext={(onClickHandler, hasNext) =>
			hasNext && (
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					onClick={onClickHandler}
					className='arrow'
					style={{ right: 15, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
					<Icon iconName='IconChevronRight' />
				</Grid>
			)
		}
		showThumbs
		renderThumbs={customRenderThumb}
		width={width}>
		{images?.map((image) => (
			<ImageMagnifier
				key={image?.src}
				src={image?.src}
				style={style}
				fallback={image?.fallback || constants.FALLBACK_IMAGE}
				ImageWidth={imageWidth}
				ImageHeight={imageHeight}
				magnifierWidth={magnifierBoxDimension}
				magnifierHeight={magnifierBoxDimension}
				zoomLevel={4}
			/>
		))}
	</Carousel>
);

ImageCarousel.defaultProps = {
	images: [
		{
			id: '1',
			src: '',
			fallback: '',
		},
	],
	width: '75%',
	magnifierBoxDimension: 400,
	imageHeight: '100%',
	imageWidth: '100%',
};

export default ImageCarousel;
