const initialState: any = {};

const linked_catalog_reducer = (state = initialState, action: any) => {
	switch (action.type) {
		case 'UPDATE_LINKED_CATALOG':
			return {
				...action?.data,
			};
		default:
			return state;
	}
};

export default linked_catalog_reducer;
