import { Box } from '@mui/material';
import { useState } from 'react';

import Image from '../Image/Image';
import { transform_image_url } from 'src/utils/ImageConstants';

interface ImageMagnifierProps {
	src?: string | undefined;
	ImageWidth?: number | string;
	ImageHeight?: number | string;
	magnifierHeight?: number;
	magnifierWidth?: number;
	zoomLevel?: number;
	fallback?: string;
	style?: any;
}

function ImageMagnifier({
	src = '',
	ImageWidth,
	ImageHeight,
	magnifierHeight = 100,
	magnifierWidth = 100,
	zoomLevel = 1.5,
	fallback,
	style,
}: ImageMagnifierProps) {
	const [[x, y], setXY] = useState([0, 0]);
	const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
	const [showMagnifier, setShowMagnifier] = useState(false);
	const image_src = transform_image_url(src, 'PDP');
	const magnified_image_src = transform_image_url(src, 'PDP_ZOOM');
	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			margin='auto'
			sx={style}
			height={ImageHeight}
			width={ImageWidth}
			onMouseEnter={(e) => {
				// update image size and turn-on magnifier
				const elem = e.currentTarget;
				const { width, height } = elem.getBoundingClientRect();
				setSize([width, height]);
				setShowMagnifier(true);
			}}
			onMouseMove={(e) => {
				// update cursor position
				const elem = e.currentTarget;
				const { top, left } = elem.getBoundingClientRect();

				// calculate cursor position on the image
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const x = e.pageX - left - window.pageXOffset;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const y = e.pageY - top - window.pageYOffset;
				setXY([x, y]);
			}}
			onMouseLeave={() => {
				// close magnifier
				setShowMagnifier(false);
			}}>
			<Image
				style={{ borderRadius: '8px', border: '1px solid #E0E0E0', minHeight: '55rem', maxHeight: '100%', objectFit: 'contain' }}
				src={image_src}
				imgClass='product-image'
				fallbackSrc={fallback}
				width={ImageWidth}
				alt='img'
			/>

			<Box
				style={{
					display: showMagnifier ? 'block' : 'none',
					position: 'absolute',

					// prevent magnifier blocks the mousemove event of img
					pointerEvents: 'none',
					// set size of magnifier
					height: `${magnifierHeight}px`,
					width: `${magnifierWidth}px`,
					// move element center to cursor pos
					top: `${y - magnifierHeight / 2}px`,
					left: `${x - magnifierWidth / 2}px`,
					opacity: '1', // reduce opacity so you can verify position
					border: '1px solid lightgray',
					backgroundColor: 'white',
					backgroundImage: `url('${magnified_image_src || fallback}')`,
					backgroundRepeat: 'no-repeat',

					// calculate zoomed image size
					backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,

					// calculate position of zoomed image.
					backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
					backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
				}}
			/>
		</Box>
	);
}

ImageMagnifier.defaultProps = {
	src: '',
	fallback: '',
	ImageWidth: '100%',
	ImageHeight: '100%',
	magnifierHeight: 40,
	magnifierWidth: 40,
	zoomLevel: 1.5,
};

export default ImageMagnifier;
