import React from 'react';
import styles from '../Cell.module.css';

interface Props {
	value: any;
	valueFormatted?: any;
}

const PriceCellRenderer: React.FC<Props> = ({ value, ...rest }) => {
	const { valueFormatted } = rest;

	return (
		<div className={styles.agGridCustomCell}>
			{valueFormatted?.amount && valueFormatted?.currency && (
				<>
					<div style={{ marginRight: 5 }} suppressContentEditableWarning>
						{valueFormatted?.currency}
					</div>
					<div suppressContentEditableWarning>{valueFormatted?.amount}</div>
				</>
			)}
		</div>
	);
};

export default PriceCellRenderer;
