import { Icon } from 'src/common/@the-source/atoms';
import { IconNames } from 'src/common/@the-source/atoms/Icon/baseTypes';
import SnackBar, { SnackbarProps } from 'src/common/@the-source/atoms/Snackbar/Snackbar';
import CustomText from './@the-source/CustomText';

export interface ToasterProps extends SnackbarProps {
	message?: React.ReactNode;
	action?: React.ReactNode;
	autoHideDuration?: number;
	state: 'error' | 'warning' | 'success' | string;
	title?: any;
	subtitle?: any;
	iconColor?: string;
	handleSecondry?: any;
	handlePrimary?: any;
	showActions: boolean;
	text_container_style?: any;
	container_style?: any;
	showCross: boolean;
	primaryBtnName?: string;
	secondryBtnName?: string;
	is_custom: boolean;
	custom_icon?: any;
	anchorOrigin?: {
		vertical: 'top' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
	show_icon: boolean;
	onClose?: () => void;
	open?: boolean;
}

const Toaster = ({
	message,
	action,
	autoHideDuration = 3000,
	anchorOrigin = { vertical: 'top', horizontal: 'right' },
	onClose = () => {},
	open = false,
	state,
	iconColor,
	title,
	subtitle,
	handleSecondry,
	primaryBtnName,
	showCross,
	secondryBtnName,
	handlePrimary,
	is_custom,
	show_icon,
	custom_icon,
	text_container_style,
	container_style,
	...rest
}: ToasterProps) => {
	const bodyResolver = () => {
		let color = '#8CB910';
		let name: IconNames = 'IconCircleCheckFilled';
		if (state === 'error') {
			name = 'IconAlertCircleFilled';
			color = '#D74C10';
		} else if (state === 'warning') {
			color = '#F0AF30';
			name = 'IconAlertTriangleFilled';
		}

		const currentIconName: IconNames = name;
		const colorName = color;
		const comp = (
			<div style={{ width: '100%', padding: '12px 20px', display: 'flex', alignItems: 'center', gap: '20px', ...container_style }}>
				{show_icon && (
					<div style={{ width: '48px' }}>
						<Icon sx={{ transform: 'scale(2.3)', width: '100%' }} color={colorName} iconName={is_custom ? custom_icon : currentIconName} />
					</div>
				)}
				<div style={{ width: '100%', flexDirection: 'column', display: 'flex', ...text_container_style }}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<CustomText color='white' type='H2'>
							{title}
						</CustomText>
						{showCross ? <Icon onClick={onClose} color='white' iconName='IconX' /> : <> </>}
					</div>

					{subtitle && (
						<CustomText color='white' type='Title'>
							{subtitle}
						</CustomText>
					)}
				</div>
			</div>
		);
		return comp;
	};
	return (
		<SnackBar
			open={open}
			message={bodyResolver()}
			action={null}
			sx={{
				background: '#2D2D2D !important',
				borderRadius: '12px',
				width: '443px',
			}}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
			anchorOrigin={anchorOrigin}
			{...rest}
		/>
	);
};

Toaster.defaultProps = {
	message: 'Message',
	action: <Icon color='white' iconName='IconX' />,
	autoHideDuration: 3000,
	onClose: () => {},
	open: true,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'right',
	},
	title: 'title',
	is_custom: false,
	subtitle: 'subtitle',
	primaryBtnName: 'primary',
	secondryBtnName: 'secondry',
	handleSecondry: () => {},
	handlePrimary: () => {},
	iconColor: '#8CB910',
	show_icon: true,
};

export default Toaster;
