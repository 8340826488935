import constants from 'src/utils/constants';

type ImageProps = {
	src: string;
	alt?: string;
	width?: any;
	height?: any;
	style?: object;
	imageFit?: string;
	imgClass?: any;
	loadingType?: any;
	fallbackSrc?: string;
	onClick?: any;
};

const Image = ({ src, alt, style, imageFit, width, height, imgClass, loadingType, onClick, fallbackSrc, ...rest }: ImageProps) => {
	const handleFallback = (e: React.SyntheticEvent<HTMLImageElement>) => {
		if (fallbackSrc) {
			e.currentTarget.src = fallbackSrc;
		}
	};

	return (
		<img
			src={src}
			alt={alt}
			onClick={onClick}
			style={{ objectFit: 'contain', ...style }}
			className={imgClass}
			loading={loadingType}
			width={width}
			onError={handleFallback}
			height={height}
			{...rest}
		/>
	);
};

Image.defaultProps = {
	src: '',
	alt: '',
	width: 20,
	height: 20,
	style: {},
	imageFit: 'contain',
	imgClass: {},
	loadingType: 'lazy',
	fallbackSrc: constants.FALLBACK_IMAGE,
};

export default Image;
