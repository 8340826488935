import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import CustomText from 'src/common/@the-source/CustomText';
import { Button, Grid, Icon, Modal, Skeleton, Stepper } from 'src/common/@the-source/atoms';
import FormBuilder from 'src/common/@the-source/molecules/FormBuilder/FormBuilder';
import { finix_env } from 'src/screens/BuyerLibrary/AddEditBuyerFlow/constants';
import { colors } from 'src/utils/theme';
import api_requests from 'src/utils/api_requests';
import useDashboard from '../useDashboard';
import TerminalModal from './TerminalModal';

const { VITE_APP_ENV } = import.meta.env;
const { PRODUCTION, LIVE, SANDBOX } = finix_env;

interface PaymentConfig {
	web_token: string;
	payment_gateway: string;
	finix_merchant_id?: string;
}

interface DirectPaymentModalProps {
	is_modal_visible: boolean;
	payment_config: PaymentConfig;
	is_from_app?: boolean;
	access_token?: string;
	height?: string;
	width?: number;
	base_url?: string;
	is_clickoutside_to_close?: boolean;
	environment?: string;
	set_is_modal_visible?: (value: boolean) => void;
	handle_complete?: (response: any) => void;
}

interface FinixFormProps {
	is_form_loading: boolean;
	set_finix_form: (value: any) => void;
	set_is_form_loading: (value: boolean) => void;
	set_is_submit_disabled: (value: boolean) => void;
}

interface FormField {
	name: string;
	label: string;
	type: string;
	required: boolean;
	placeholder: string;
	value: number | string | null;
}

interface SubmitChargePayload extends FieldValues {
	external_token: string;
}

const steps = [
	{
		label: 'Amount',
	},
	{
		label: 'Card details',
	},
];

const stepper_styles = {
	'& .MuiStepIcon-text': {
		fontSize: '1.4rem', // size of the step index text here
	},
	width: '70%',
};

const basic_fields: FormField[] = [
	{
		name: 'Amount', // Kept in capitals to show error msg
		type: 'amount',
		label: 'Amount',
		required: true,
		placeholder: 'Enter amount',
		value: null,
	},
	{
		name: 'buyer_info',
		type: 'text',
		required: false,
		label: 'Customer name',
		placeholder: 'Enter Customer name',
		value: null,
	},
	{
		name: 'order_info',
		type: 'text',
		required: false,
		label: 'Order details',
		placeholder: 'Enter Order details',
		value: null,
	},
];

const skeletons = [{ xs: 12 }, { xs: 12 }, { xs: 6 }, { xs: 6 }, { xs: 12 }, { xs: 6 }, { xs: 6 }, { xs: 6 }, { xs: 6 }];

const get_finix_session_key = (env: string, merchant_id: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		(window as any)?.Finix?.Auth(env, merchant_id, (session_key: string) => {
			if (session_key) {
				resolve(session_key);
			} else {
				reject(new Error('Failed to get session key'));
			}
		});
	});
};

const FormSkeleton = () => {
	return (
		<Box component='form' noValidate autoComplete='off'>
			<Grid container spacing={3}>
				{_.map(skeletons, (skeleton) => (
					<Grid item xs={skeleton.xs} key={`${skeleton.xs}__${Math.random() * 100}`}>
						<Skeleton variant='rectangular' width='100%' height={48} sx={{ borderRadius: '8px' }} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

const FinixForm = ({ is_form_loading, set_finix_form, set_is_submit_disabled, set_is_form_loading }: FinixFormProps) => {
	const options = {
		showAddress: true,
		showLabels: true,
		labels: {
			name: 'Full Name',
		},
		showPlaceholders: true,
		placeholders: {
			name: 'Full Name',
			number: 'XXXX XXXX XXXX XXXX',
		},
		hideFields: ['address_line2'],
		requiredFields: ['name'],
		hideErrorMessages: false,
		errorMessages: {
			name: 'Please enter a valid name',
			address_city: 'Please enter a valid city',
		},
		styles: {
			default: {
				color: '#000',
				border: `1px solid ${colors.light_grey}`,
				borderRadius: '8px',
				padding: '8px 16px',
				fontSize: '12px',
				boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)',
			},
			error: {
				color: colors.red,
				border: `1px solid ${colors.red}`,
			},
		},
		onUpdate: (state: any, binInformation: any, formHasErrors: any) => {
			set_is_submit_disabled(formHasErrors);
		},
		onLoad: () => {
			set_is_form_loading(false);
		},
	};

	useEffect(() => {
		const form = (window as any)?.Finix.CardTokenForm('finix_form', options);
		set_finix_form(form);
	}, []);

	return (
		<Grid height={'56vh'}>
			{is_form_loading && <FormSkeleton />}
			<div id='finix_form' />
		</Grid>
	);
};

const DirectPaymentModal: React.FC<DirectPaymentModalProps> = React.memo(
	({
		is_modal_visible,
		payment_config,
		is_from_app = false,
		access_token = '',
		height = 'auto',
		width = 500,
		base_url = '',
		is_clickoutside_to_close = false,
		environment = '',
		set_is_modal_visible,
		handle_complete,
	}) => {
		const [finix_form, set_finix_form] = useState<any>(null);
		const [active_step, set_active_step] = useState<number>(0);
		const [is_btn_loading, set_is_btn_loading] = useState<boolean>(false);
		const [is_form_loading, set_is_form_loading] = useState<boolean>(true);
		const [is_submit_disabled, set_is_submit_disabled] = useState<boolean>(false);
		const { complete, setIsPolling, is_terminal_modal_visible, set_is_terminal_modal_visible, transaction_data } = useDashboard();
		const methods = useForm();
		const { getValues, handleSubmit, control, reset, setValue } = methods;
		const _env_val = is_from_app && !_.isEmpty(environment) ? environment : VITE_APP_ENV;
		const _env = _env_val === PRODUCTION ? LIVE : SANDBOX;

		const reset_states = () => {
			set_active_step(0);
			set_is_form_loading(true);
			set_is_btn_loading(false);
			set_is_submit_disabled(false);
			reset(); // reset form
		};

		useEffect(() => {
			if (!is_from_app) {
				if (!is_terminal_modal_visible) return;
				set_is_modal_visible && set_is_modal_visible(false);
				reset_states();
			}
		}, [is_terminal_modal_visible]);

		const handle_submit_charge = async (payload: SubmitChargePayload) => {
			try {
				set_is_btn_loading(true);
				const { Amount, ...rest } = payload;
				const payload_to_be_sent = {
					custom_amount_to_pay: Amount,
					collect_payment_method: 'card', // for now
					...rest,
				};
				const { finix_merchant_id = '' } = payment_config || {};
				// const _env = VITE_APP_ENV === PRODUCTION ? LIVE : SANDBOX;

				const session_key = await get_finix_session_key(_env, finix_merchant_id);
				const updated_payload = {
					...payload_to_be_sent,
					attributes: {
						fraud_session_id: session_key,
					},
				};
				const response: any = await api_requests.buyer.collect_direct_payment(updated_payload, access_token, base_url);
				if (response?.transaction_status === 'pending' && !is_from_app) {
					setIsPolling({ data: response, state: true });
				}
				if (is_from_app && handle_complete) {
					const updated_response = {
						action: 'submit',
						transaction_details: response,
					};
					handle_complete(updated_response);
				} else {
					complete(response);
				}
			} catch (error) {
				console.error(error);
				const _response = {
					transaction_status: 'failed',
					transaction_amount: '',
					transaction_header: 'Payment failed',
				};
				if (is_from_app && handle_complete) {
					handle_complete(_response);
				} else {
					complete(_response);
				}
			} finally {
				set_is_btn_loading(false);
			}
		};

		const handle_tokenize_card: SubmitHandler<FieldValues> = (values) => {
			try {
				set_is_btn_loading(true);
				// const _env = VITE_APP_ENV === PRODUCTION ? LIVE : SANDBOX;
				finix_form?.submit(_env, payment_config.web_token, (err: any, res: any) => {
					if (err) {
						console.error(err);
						set_is_btn_loading(false);
						return;
					}
					const tokenData = res?.data || {};
					const token = tokenData?.id;
					const final_payload: SubmitChargePayload = {
						...values,
						external_token: token,
					};
					handle_submit_charge(final_payload);
				});
			} catch (error) {
				console.error(error);
			}
		};

		const handle_next: SubmitHandler<FieldValues> = (values) => {
			if (active_step === 0) {
				set_is_form_loading(true);
				set_active_step((prev) => prev + 1);
			} else {
				handle_tokenize_card(values);
			}
		};

		const render_field_component = (field: FormField) => {
			const is_amount_field = field.type === 'amount';
			return (
				<FormBuilder
					name={field.name}
					placeholder={field.placeholder}
					defaultValue={field.value}
					type={field.type}
					size={is_amount_field ? 'small' : 'medium'}
					control={control}
					InputLabelProps={is_amount_field ? { shrink: true } : {}}
					validations={{
						required: field.required,
						amount: is_amount_field,
					}}
					label={is_amount_field ? '' : field.label}
					style={{
						margin: '1rem 0',
					}}
					start_icon={
						is_amount_field ? <Icon color={colors.grey_800} iconName='IconCurrencyDollar' sx={{ height: '24px', width: '24px' }} /> : null
					}
					register={methods.register}
					getValues={getValues}
					setValue={setValue}
				/>
			);
		};

		const render_amount_field = (field: FormField) => (
			<Grid item mb={1} pt={3} pl={2} pr={2} pb={2} bgcolor={colors.grey_600} borderRadius='12px'>
				<CustomText type='H6'>{field.label}</CustomText>
				{render_field_component(field)}
			</Grid>
		);

		const render_basic_fields = (
			<Grid mt={1} pl={3} pr={3}>
				<FormProvider {...methods}>
					{_.map(basic_fields, (field: FormField) =>
						(field.type === 'amount' ? render_amount_field(field) : render_field_component(field)),
					)}
				</FormProvider>
			</Grid>
		);

		const render_card_fields = (
			<Grid mt={1} pl={3} pr={3}>
				<FinixForm
					is_form_loading={is_form_loading}
					set_finix_form={set_finix_form}
					set_is_form_loading={set_is_form_loading}
					set_is_submit_disabled={set_is_submit_disabled}
				/>
			</Grid>
		);

		const render_content = (
			<Grid id='direct-payment-content'>
				<Box display='flex' justifyContent='center' width='100%' mb={2}>
					<Stepper steps={steps} activeStep={active_step} sx={{ ...stepper_styles }} />
				</Box>
				<Grid
					sx={{
						overflowY: 'scroll',
						'&::-webkit-scrollbar': {
							width: '0',
						},
						maxHeight: height ?? '90%',
					}}>
					{active_step === 0 ? render_basic_fields : render_card_fields}
				</Grid>
			</Grid>
		);

		const render_footer = (
			<Grid container display='flex' justifyContent={active_step === 1 ? 'space-between' : 'flex-end'} alignItems='center'>
				{active_step === 1 && (
					<Grid
						item
						display='flex'
						justifyContent='space-between'
						width={'40%'}
						p={1.5}
						borderRadius={'8px'}
						alignItems='center'
						bgcolor={colors.grey_600}>
						<CustomText type='Subtitle'>Charge</CustomText>
						<CustomText type='Subtitle'>$ {getValues('Amount')}</CustomText>
					</Grid>
				)}
				<Grid item>
					<Button
						disabled={is_submit_disabled || Number(getValues('Amount')) === 0}
						onClick={handleSubmit(handle_next)}
						loading={is_btn_loading}>
						{active_step === 0 ? 'Next' : 'Charge'}
					</Button>
				</Grid>
			</Grid>
		);

		const handle_close_modal = () => {
			reset_states();
			set_is_modal_visible && set_is_modal_visible(false);
			if (is_from_app && handle_complete) {
				const response = {
					action: 'cancel',
				};
				handle_complete(response);
			}
		};

		return (
			<>
				<Modal
					title='Direct Payment'
					open={is_modal_visible}
					onClose={handle_close_modal}
					children={render_content}
					footer={render_footer}
					width={width}
					is_clickoutside_to_close={is_clickoutside_to_close}
				/>
				{is_terminal_modal_visible && (
					<TerminalModal
						is_visible={is_terminal_modal_visible}
						transaction_data={transaction_data}
						close={() => {
							set_is_terminal_modal_visible(false);
							handle_close_modal();
						}}
						retry={() => set_is_terminal_modal_visible(false)}
					/>
				)}
			</>
		);
	},
);

export default DirectPaymentModal;
