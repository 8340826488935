import { PERMISSIONS } from 'src/casl/permissions';

export const columnDef = [
	{
		suppressMenu: true,
		field: 'name',
		headerName: 'Modules',
		flex: 1,
		dtype: 'text',
		editable: false,
		hideFilter: true,
	},
	{
		suppressMenu: true,
		field: 'is_active',
		headerName: 'Active',
		flex: 1,
		cellRenderer: 'agCheckboxCellRenderer',
		cellEditor: 'agCheckboxCellEditor',
		editable: false,
		disable: true,
	},
];

export const EDIT_PRODUCT_PRICE_KEY = 'Edit Product Price';

export const TENANT_CONSTANTS: any = {
	DAM: { modules: ['Files'], submodules: [] },
	Payments: { modules: ['Payments'], submodules: ['Saved cards', 'Credits'] },
	'Import / Export': { modules: ['Import / Export'], submodules: [] },
	Labels: ['create_labels'],
	WizPay: ['wizpay_dashboard_full_access'],
	Credits: { modules: [], submodules: ['Credits'] },
	Invoices: { modules: ['Invoice'], submodules: [] },
	Reports: { modules: ['Report'], submodules: [] },
	WizAI: { modules: ['WizAI'], submodules: [] },
	[EDIT_PRODUCT_PRICE_KEY]: [PERMISSIONS.cart_price_override.slug],
};
export const WRITTEN_BY_WRITTEN_FOR_KEY = 'Written By Written for';

export const BARCODE = {
	TABLE_CONFIG: [
		{
			suppressMenu: true,
			field: 'name',
			headerName: 'Barcode Type',
			flex: 1,
			dtype: 'text',
			editable: false,
			hideFilter: true,
		},
		{
			suppressMenu: true,
			field: 'filter_logic.key_type',
			headerName: 'Logic Type',
			flex: 1,
			dtype: 'text',
			editable: false,
			hideFilter: true,
		},
	],
	FORM_FIELDS: [{ name: 'Select Attribute/Property', id: 'key', type: 'select', options: [] }],
};
export const BARCODE_TYPE_OPTION = [
	{ label: 'SKU ID', value: 'barcode' },
	{ label: 'Custom', value: 'upc_a' },
];
export const DEFAULT_VALUES = { name: 'Custom', data_type: 'text', logic: 'search', key_type: 'attribute', is_default: true };

export const DEFAULT_BARCODE = [
	{
		name: 'SKU ID',
		type: 'barcode',
		logic: 'search',
		filter_logic: { key: 'sku_id', key_type: 'sku_id' },
		is_default: true,
		data_type: 'string',
	},
];
