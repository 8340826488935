import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';

type RadioBaseProps = Pick<MuiRadioProps, 'size' | 'checked' | 'disabled' | 'onChange'>;

export interface RadioProps extends RadioBaseProps {
	checked?: boolean;
	size?: any;
	onChange?: any;
	disabled?: boolean;
}

const Radio = ({ checked, size, disabled, onChange, ...rest }: RadioProps) => (
	<MuiRadio size={size} checked={checked} disabled={disabled} onChange={onChange} {...rest} />
);

Radio.defaultProps = {
	size: 'medium',
	checked: false,
	onChange: () => {},
	disabled: false,
};
export default Radio;
