import utils from '../utils';

const user = {
	login: () => {
		return utils.request({
			url: 'auth/login/',
			method: 'POST',
			data: {},
		});
	},

	refresh_token: () => {
		return utils.request({
			url: 'auth/token/refresh/',
			method: 'POST',
			data: {},
		});
	},

	verify_token: () => {
		return utils.request({
			url: 'auth/token/verify/',
			method: 'POST',
			data: {},
		});
	},

	forgot_password: () => {
		return utils.request({
			url: 'users/password_reset/',
			method: 'POST',
			data: {},
		});
	},

	reset_password: (token: string) => {
		return utils.request({
			url: `users/password_reset_confirm/${token}`,
			method: 'POST',
			data: {},
		});
	},
};

export default user;
