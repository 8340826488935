import { formatNumberWithCommas } from 'src/utils/common';
import styles from '../Cell.module.css';

interface Props {
	value: any;
	valueFormatted?: any;
	node?: any;
}

const CurrencyIconCellRenderer: React.FC<Props> = ({ value, node, ...rest }) => {
	const { valueFormatted } = rest;
	const formattedNumber = formatNumberWithCommas(valueFormatted || value, true);
	let currency = node?.data?.CURRENCY ?? '$';
	return (
		<div className={styles.agGridCustomCell}>
			{currency} {formattedNumber}
		</div>
	);
};

export default CurrencyIconCellRenderer;
